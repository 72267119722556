<template>
  <div class="row">
    <div class="col-lg-2">{{ $t('kpi.kpi.' + name) }}</div>
    <div class="col-lg-6 mb-1">
      <div class="progress kpiProgress">
        <div
          class="progress-bar text-dark"
          role="progressbar"
          :style="{
                                width: getKpiValue(kpi.completion) + '%',
                                backgroundColor: getBarColor(kpi.completion)}"
        >{{ getKpiValue(kpi.current) | formatNumber }}
        </div>
      </div>
      <div class="progress kpiProgress">
        <div
          class="progress-bar text-dark"
          role="progressbar"
          :style="{
                                width: getKpiValue(kpi.medianCompletion) + '%',
                                backgroundColor: getBarColor(kpi.medianCompletion)}"
        >{{ getKpiValue(kpi.median) | formatNumber }}
        </div>
      </div>
    </div>
    <div class="col-lg-1 text-center">{{ getKpiValue(kpi.target) }}</div>
    <div class="col-lg-1 text-center">{{ getKpiValue(kpi.completion) | formatNumber }}%</div>
    <div class="col-lg-1 text-center">{{ getKpiValue(kpi.median) | formatNumber }}</div>
    <div class="col-lg-1 text-center">{{ getKpiValue(kpi.medianCompletion) | formatNumber }}%</div>
  </div>
</template>

<script>
export default {
  name: 'KpiComparisonProgressRow',
  props: {
    name: {
      type: String,
      required: true
    },
    kpi: {
      type: Object,
      required: true
    }
  },
  data () {
    return {}
  },
  methods: {
    getKpiValue (obj) {
      const paths = this.name.split('.')
      let current = obj
      let i

      for (i = 0; i < paths.length; i++) {
        current = current[paths[i]]
      }

      return current
    },
    getBarColor (source) {
      let completion = this.getKpiValue(source)
      if (completion > 100) {
        completion = 100
      }

      return 'hsl(' + completion + ', 100%, 50%)'
    }
  }
}
</script>

<style lang="scss" scoped>
  .kpiProgress {
    height: 13px;
    font-size: 11px;
  }
  .kpiProgress:first-of-type {
    margin-bottom: 1px;
  }
</style>
