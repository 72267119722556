<template>
  <app-module-view>
    <template slot="body">
      <kpi-comparison-filter></kpi-comparison-filter>
      <div class="card" v-if="isLoaded">
        <div class="card-header">{{ $t('kpi.kpi_fulfilment') }}</div>
        <div class="card-body">
          <preloader v-if="callingAPI"></preloader>
          <div class="row font-weight-bold">
            <div class="col-lg-2">KPI</div>
            <div class="col-lg-6">{{ $t('kpi.kpi_current') }}</div>
            <div class="col-lg-1 text-center">{{ $t('kpi.kpi_target') }}</div>
            <div class="col-lg-1 text-center">VS {{ $t('kpi.kpi_target') }}</div>
            <div class="col-lg-1 text-center">{{ $t('kpi.kpi_median') }}</div>
            <div class="col-lg-1 text-center">VS {{ $t('kpi.kpi_median') }}</div>
          </div>
          <kpi-progress-row name="userKpi.articleCnt" :kpi="kpiComparison"></kpi-progress-row>
          <kpi-progress-row name="userKpi.articleEditCnt" :kpi="kpiComparison"></kpi-progress-row>
          <kpi-progress-row name="articleKpi.articleType.photostoryCnt" :kpi="kpiComparison"></kpi-progress-row>
          <kpi-progress-row name="articleKpi.articleType.quizCnt" :kpi="kpiComparison"></kpi-progress-row>
          <kpi-progress-row name="articleKpi.articleType.videoCnt" :kpi="kpiComparison"></kpi-progress-row>
          <kpi-progress-row name="articleKpi.imagesCnt" :kpi="kpiComparison"></kpi-progress-row>
          <kpi-progress-row name="articleKpi.videosCnt" :kpi="kpiComparison"></kpi-progress-row>
          <kpi-progress-row name="articleKpi.articleView.viewCnt" :kpi="kpiComparison"></kpi-progress-row>
          <kpi-progress-row name="articleKpi.articlePerf.videoViewCnt" :kpi="kpiComparison"></kpi-progress-row>
        </div>
      </div>
      <div class="card" v-if="isLoaded">
        <div class="card-header">{{ $t('kpi.articles_breakdown') }}</div>
        <div class="card-body">
          <div class="row justify-content-around">
            <div class="col-lg-3 doughnutWrapper">
              <doughnut-chart :height="150" :chart-data="articleTypeChart"></doughnut-chart>
            </div>
            <div class="col-lg-3 doughnutWrapper">
              <doughnut-chart :height="150" :chart-data="articleNatureChart"></doughnut-chart>
            </div>
            <div class="col-lg-3 doughnutWrapper">
              <doughnut-chart :height="150" :chart-data="articleViewChart"></doughnut-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-if="isLoaded">
        <div class="card-header">
          {{ $t('beUser.department') }}
          <button class="btn btn-success" @click.prevent="downloadDeptSum()">
            <i class="icon-cloud-download"></i> CSV
          </button>
        </div>
        <div class="card-body">
          <app-data-table
            :data="userSummaries"
            :pagination="false"
            :config="kpiSummariesDtConfig"
          >
          </app-data-table>
        </div>
      </div>
      <div class="card" v-if="isLoaded && this.filter.id">
        <div class="card-header">{{ $t('kpi.digital_articles') }}</div>
        <div class="card-body">
          <app-data-table
            :data="articleSummaries"
            :pagination="false"
            :config="digitalArticlesDtConfig"
            :actions-in-blank-window="true"
            action-id-param="article.id"
          >
          </app-data-table>
        </div>
      </div>
      <div class="card" v-if="isLoaded">
        <div class="card-header">{{ $t('kpi.print_articles') }}</div>
        <div class="card-body">
          <app-data-table
            :data="printArticles"
            :pagination="false"
            :config="printArticlesDtConfig"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import KpiComparisonFilter from './KpiComparisonFilter'
import KpiComparisonProgressRow from './KpiComparisonProgressRow'
import DoughnutChart from '../chart/DoughnutChart'
import DataTable from '../shared/DataTable'
import UserService from '../../services/user/UserService'

export default {
  name: 'KpiComparison',
  data () {
    return {
      isLoading: false,
      printArticlesDtConfig: {
        fields: {
          id: this.$t('printArticle.id'),
          title: this.$t('printArticle.title'),
          publicationExpanded: this.$t('printArticle.publication'),
          native: this.$t('printArticle.native'),
          type: this.$t('printArticle.type'),
          quality: this.$t('printArticle.quality'),
          sharedUsers: this.$t('printArticle.sharedUsers')
        },
        render: {
          native: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          },
          sharedUsers: (userIDs) => {
            return userIDs.map(userID => {
              return this.$store.getters['user/userById'](userID).username
            }).join(', ')
          },
          publicationExpanded: publicationExpanded => {
            return publicationExpanded.printTitle + ' - ' + publicationExpanded.title
          }
        }
      },
      digitalArticlesDtConfig: {
        fields: {
          'article.title': this.$t('article.title'),
          'article.orderDate': this.$t('article.order_date'),
          'article.userIDs': this.$t('article.sharedUsers'),
          'kpi.articleKpi.articleView.pageViewCnt': this.$t('kpi.kpi.articleKpi.articleView.pageViewCnt'),
          'kpi.articleKpi.imagesCnt': this.$t('kpi.kpi.articleKpi.imagesCnt'),
          'kpi.articleKpi.videosCnt': this.$t('kpi.kpi.articleKpi.videosCnt'),
          'kpi.articleKpi.articlePerf.engagedTime': this.$t('kpi.kpi.articleKpi.articlePerf.engagedTime'),
          'kpi.articleKpi.articlePerf.fbShares': this.$t('kpi.kpi.articleKpi.articlePerf.fbShares'),
          'kpi.articleKpi.articlePerf.disqusComments': this.$t('kpi.kpi.articleKpi.articlePerf.disqusComments')
        },
        render: {
          'article.userIDs': (userIDs) => {
            return userIDs.map(userID => {
              return this.$store.getters['user/userById'](userID).username
            }).join(', ')
          }
        },
        actions: {
          detail: 'articleByDocumentId'
        }
      },
      kpiSummariesDtConfig: {
        fields: {
          userId: this.$t('printArticle.id'),
          'groupPerformance.print_quality': this.$t('kpi.groupPerformance.printQuality'),
          'groupPerformance.online_quality': this.$t('kpi.groupPerformance.onlineQuality'),
          'groupPerformance.subscriptions': this.$t('kpi.groupPerformance.subscriptions'),
          'groupPerformance.social_engagement': this.$t('kpi.groupPerformance.socialEngagement'),
          'groupPerformance.video_quality': this.$t('kpi.groupPerformance.videoQuality'),
          'groupPerformance.article_quantity': this.$t('kpi.groupPerformance.articlesQuantity'),
          'groupPerformance.video_quantity': this.$t('kpi.groupPerformance.videosQuantity'),
          'groupPerformance.native_quantity': this.$t('kpi.groupPerformance.nativeQuantity'),
          performance: this.$t('kpi.performance')
        },
        render: {
          userId: (userId) => {
            const user = this.$store.getters['user/userById'](userId)
            if (user) {
              return UserService.getUserInfo(user)
            }

            return userId
          },
          'groupPerformance.print_quality': (floatVal) => this.$options.filters.formatNumber(floatVal),
          'groupPerformance.online_quality': (floatVal) => this.$options.filters.formatNumber(floatVal),
          'groupPerformance.subscriptions': (floatVal) => this.$options.filters.formatNumber(floatVal),
          'groupPerformance.social_engagement': (floatVal) => this.$options.filters.formatNumber(floatVal),
          'groupPerformance.video_quality': (floatVal) => this.$options.filters.formatNumber(floatVal),
          'groupPerformance.article_quantity': (floatVal) => this.$options.filters.formatNumber(floatVal),
          'groupPerformance.video_quantity': (floatVal) => this.$options.filters.formatNumber(floatVal),
          'groupPerformance.native_quantity': (floatVal) => this.$options.filters.formatNumber(floatVal),
          performance: (floatVal) => this.$options.filters.formatNumber(floatVal)
        },
        highlightRow: (record) => {
          return record.userId === this.filter.id
        }
      }
    }
  },
  methods: {
    downloadDeptSum () {
      if (this.isLoading) {
        return
      }
      this.isLoading = true

      this.$store.dispatch('kpiSummary/downloadDeptSum')
        .then(() => {
          this.isLoading = false
        })
        .catch(error => {
          console.log(error)
          this.isLoading = false
        })
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    kpiComparison () {
      return this.$store.getters['kpiComparison/detail']
    },
    printArticles () {
      return this.$store.getters['printArticle/list']
    },
    userSummaries () {
      return this.$store.getters['kpiSummary/listUser']
    },
    articleSummaries () {
      return this.$store.getters['kpiSummary/listArticle']
    },
    filter () {
      return this.$store.getters['kpiSummary/filter']
    },
    isLoaded () {
      return this.$store.getters['kpiComparison/loaded']
    },
    articleTypeChart () {
      return {
        labels: [
          this.$t('kpi.kpi.articleKpi.articleType.internalCnt'),
          this.$t('kpi.kpi.articleKpi.articleType.externalCnt'),
          this.$t('kpi.kpi.articleKpi.articleType.photostoryCnt'),
          this.$t('kpi.kpi.articleKpi.articleType.quizCnt'),
          this.$t('kpi.kpi.articleKpi.articleType.videoCnt')
        ],
        datasets: [
          {
            data: [
              this.kpiComparison.current.articleKpi.articleType.internalCnt.toFixed(2),
              this.kpiComparison.current.articleKpi.articleType.externalCnt.toFixed(2),
              this.kpiComparison.current.articleKpi.articleType.photostoryCnt.toFixed(2),
              this.kpiComparison.current.articleKpi.articleType.quizCnt.toFixed(2),
              this.kpiComparison.current.articleKpi.articleType.videoCnt.toFixed(2)
            ],
            backgroundColor: [
              '#55ce63',
              '#009efb',
              '#ffbc34',
              '#f62d51',
              '#2f3d4a'
            ]
          }
        ]
      }
    },
    articleNatureChart () {
      return {
        labels: [
          this.$t('kpi.kpi.articleKpi.articleNature.nativeCnt'),
          this.$t('kpi.kpi.articleKpi.articleNature.prCnt'),
          this.$t('kpi.kpi.articleKpi.articleNature.editorialCnt')
        ],
        datasets: [
          {
            data: [
              this.kpiComparison.current.articleKpi.articleNature.nativeCnt.toFixed(2),
              this.kpiComparison.current.articleKpi.articleNature.prCnt.toFixed(2),
              this.kpiComparison.current.articleKpi.articleNature.editorialCnt.toFixed(2)
            ],
            backgroundColor: [
              '#55ce63',
              '#009efb',
              '#ffbc34'
            ]
          }
        ]
      }
    },
    articleViewChart () {
      return {
        labels: [
          this.$t('kpi.kpi.articleKpi.articleView.pageViewCnt'),
          this.$t('kpi.kpi.articleKpi.articleView.galleryViewCnt')
        ],
        datasets: [
          {
            data: [
              this.kpiComparison.current.articleKpi.articleView.pageViewCnt.toFixed(2),
              this.kpiComparison.current.articleKpi.articleView.galleryViewCnt.toFixed(2)
            ],
            backgroundColor: [
              '#55ce63',
              '#009efb'
            ]
          }
        ]
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    Preloader: Preloader,
    kpiComparisonFilter: KpiComparisonFilter,
    kpiProgressRow: KpiComparisonProgressRow,
    doughnutChart: DoughnutChart,
    appDataTable: DataTable
  }
}
</script>

<style lang="scss" scoped>
.doughnutWrapper {
  height: 150px;
}
</style>
