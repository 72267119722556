<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterAll">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.department"
                :options="managedDepartments"
                optionTitle="name"
                id="kpiFilter_department"
                :label="$t('beUser.department')"
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-select
                v-model="filter.id"
                :options="usersInDepartment"
                optionTitle="username"
                id="kpiFilter_user"
                :label="$t('beUser.username')"
              >
              </app-select>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                v-model="filter.startDate"
                type="date"
                id="kpiFilter_week"
                :enable="enableMondays()"
                :week-numbers="true"
                :label="$t('kpi.comparison_week')">
              </app-datepicker>
            </div>
            <div class="col-lg-2">
              <button
                class="btn btn-success pull-right m-t-30"
                @click.prevent="filterAll()"
              >{{ $t('beUser.filter.search') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Select from '../form/select/Select'
import KpiFilter from '../../model/KpiFilter'
import PrintArticleFilter from '../../model/PrintArticleFilter'
import Datepicker from '../form/Datepicker'

export default {
  name: 'KpiFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(KpiFilter),
      printArticleFilter: this._.cloneDeep(PrintArticleFilter)
    }
  },
  components: {
    appSelect: Select,
    appDatepicker: Datepicker
  },
  computed: {
    managedDepartments () {
      return this.$store.getters['department/getManagedByCurrentUser']
    },
    usersInDepartment () {
      if (this.filter.department) {
        return this.$store.getters['user/usersInDepartment'](this.filter.department)
      }

      return null
    }
  },
  methods: {
    filterAll () {
      this.filterKpi()
      this.filterPrintArticles()
    },
    filterKpi () {
      this.$store.commit('kpiComparison/setFilter', this.filter)
      this.$store.commit('kpiSummary/setFilter', this.filter)
      this.$store.dispatch('kpiComparison/fetch')
      this.$store.dispatch('kpiSummary/fetchUserList')
      this.$store.dispatch('kpiSummary/fetchArticleList')
    },
    filterPrintArticles () {
      this.printArticleFilter.department = this.filter.department
      this.printArticleFilter.user = this.filter.id
      this.printArticleFilter.publicationWeek = this.filter.startDate
      this.$store.commit('printArticle/setFilter', this.printArticleFilter)
      this.$store.dispatch('printArticle/fetch')
    },
    enableMondays () {
      return [
        date => {
          return date.getDay() === 1
        }
      ]
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['kpiComparison/filter']
  }
}
</script>
